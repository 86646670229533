<!--
 * @Author: kai 17660422924@163.com
 * @Date: 2024-03-15 16:51:20
 * @LastEditors: kai 17660422924@163.com
 * @LastEditTime: 2024-05-29 10:01:11
 * @FilePath: \mmc-vue-tianmu\src\components\mount\MMC_Gimbal_R3\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="cpt-MMC_Gimbal_R3" :style="containerStyle">
    <div v-interact class="hd">
      <div class="left ml8">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="title">测流雷达R3</div>
      </div>
      <div class="close" @click="$emit('close')">关闭</div>
    </div>
    <div class=" fw800  w518   f18 jcsb   bac" style="justify-content: space-around;">
       <div class="ml2 w100 tc">传感器</div>
       <div class="ml2 w100 tc">当前值</div>
       <div class="ml2 w100 tc">最小值</div>
       <div class="ml2 w100 tc">最大值</div>
      </div>
    <div class="bd">
     <echartDom class="mb10 mt10" :airData="cnswy1.device_water_heigh" chartRef='device_water_heigh_dom'></echartDom>
     <echartDomv2 class="mb10 mt10"  :airData="cnswy1.device_water_speed" chartRef='device_water_speed_dom'></echartDomv2>
     <echartDomv3 class="mb10 mt10"  :airData="cnswy1.waterLevel"  chartRef='waterLevel_dom'></echartDomv3>
      <!-- <div class="form-wrap">
        <div class="form-item">
          <div class="label-box">水面距离:</div>
          <div class="input-box">
            <div class="text"><div :class="device_water_heigh_style">{{cnswy1.device_water_heigh}} </div><span>米(M)</span></div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">水流速度:</div>
          <div class="input-box">
            <div class="text"><div :class="device_water_speed_style">{{cnswy1.device_water_speed}}</div><span>米每秒(M/S)</span></div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">水位:</div>
          <div class="input-box">
            <div class="text"><div class="green">{{cnswy1.waterLevel}} </div><span>米(M)</span></div>
          </div>
        </div>
      </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { MMC_Gimbal_R3 } from "./message/index.js";
import echartDom from "./com/echartsDom";
import echartDomv2 from "./com/echartsDom";
import echartDomv3 from "./com/echartsDom";


import { EarlyWarningAPI } from '@/api';
export default {
  components:{
    echartDom,
    echartDomv2,
    echartDomv3
  },
  data() {
    return {
      topic:"",
        cnswy1:{
          elevationAnomaly:0,
          device_water_heigh:0,
          device_water_speed:0,
          device_euler:0,
          waterLevel:0
        },
        device_water_speed_style:'green',
        device_water_heigh_style:'green',
        warningList:[]
    };
  },
  props: {
    containerStyle: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    payload(){
      return this.$store.state.uavApplications.payloadR3
    }
  },
  watch: {
    "payload":{
      async handler(value) {
        if (value) {
       let data=  MMC_Gimbal_R3.on_receive(value)
       if(data){
        this.cnswy1= data
        if(this.warningList&&data.device_water_heigh&&data.device_water_speed){
          this.device_water_heigh_style= this.checkRange(data.device_water_heigh,this.warningList[0])
          // 水流
         this.device_water_speed_style= this.checkRange(data.device_water_speed,this.warningList[1])
        }
         
       }
        }
      },
      deep: true,
      immediate: true,
    }
  },
  beforeDestroy() {
  },
  mounted() {
    this.alertInformationFn()
    // setInterval(() => {
    // let k=  this.checkRange(30,this.warningList[0])
    // this.device_water_speed_style= k
    // }, 2000);
  },
  methods: {
   async alertInformationFn(){
   let res = await EarlyWarningAPI.alertInformation()
   console.log(res,'res');
   if(res.code==200){
    this.warningList = res.data.records
   }
    },
    checkRange(valueToCheck, data) {
      const ranges = [
  ['green', data.normalValue],
  ['yellow', data.warningValue],
  ['or', data.abnormalValue],
  ['red', data.criticalValue]
];
  for (const range of ranges) {
    const [fieldName, rangeValues] = range;
    const [start, end] = rangeValues.split(",").map(Number);
    if (valueToCheck >= start && valueToCheck <= end) {
      return fieldName;
    }
  }
  return 'green';
}

  },
};
</script>

<style lang="scss" scoped>
.cpt-MMC_Gimbal_R3 {
  position: absolute;
  top: -1px;
  right: 0;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;
  width: 542px;
  // height: 185px;
  box-sizing: border-box;
  .bac{
    color:#bac0c4
  }

  // padding: 10px 20px;
  .hd {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;

    .left {
      display: flex;
      align-items: center;

      .title {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }

  .bd {
    // padding: 0 20px;
    // display: flex;
    // justify-content: space-between;
    overflow-y: auto;
    // height: 230px;
    overflow-x: hidden;
    .form-wrap {
      flex-shrink: 0;

      .form-item {
        display: flex;
        align-items: center;
        height: 40px;

        .label-box {
          color: #cbd0eb;
          width: 75px;
        }

        .btn-default {
          padding: 0 10px !important;
          background: #004fff;
          border-color: #004fff;
        }

        .input-box {
          display: flex;
          align-items: center;

          .mono {
            display: flex;
            flex-direction: column;

            .mono-long {
              display: flex;
              justify-content: space-between;
              width: 168px;
              height: 17px;
              background: url("~@/assets/images/mount_long.png") no-repeat;
              background-size: 100%;
              margin-bottom: 5px;

              .mono_box {
                width: 28px;
                height: 13px;
                border-radius: 3px;
                margin-top: 2px;
                cursor: pointer;
              }

              .active {
                background: url("~@/assets/images/mount/mount_control.png")
                  no-repeat !important;
                background-size: 100% 100%;
              }
            }

            .text {
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #ffffff;
              display: flex;
              justify-content: space-between;
              font-size: 14px;
            }
          }
        }
      }
    }

    .rocker-wrap {
      flex: 1;

      .item-group {
        display: flex;
        justify-content: space-evenly;

        .item-box {
          .icon-box {
            width: 32px;
            height: 32px;
            background: #004fff;
            border-radius: 32px;
            display: flex;
            align-items: center;
            justify-content: center;

            .iconfont {
              font-size: 24px;
              color: #dce9ff;
              cursor: pointer;
            }
          }
        }
      }

      .rocker {
        margin: 10px auto 0;
        width: 150px;
        height: 150px;
        position: relative;
        margin: 0 auto;
        background: center url("~@/assets/images/accident/caozuo.png") no-repeat;
        background-size: 100% 100%;

        .shangUp {
          position: absolute;
          left: 37%;
          top: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .xaiUp {
          position: absolute;
          left: 37%;
          bottom: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .zuoUp {
          position: absolute;
          left: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .youUp {
          position: absolute;
          right: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
      }
    }
  }
}

::v-deep {
  .el-select {
    width: 165px;
    margin-right: 5px;

    .el-input {
      .el-input__inner {
        background: #000000;
        border: 1px solid #004fff;
        color: #dce9ff;
      }
    }
  }

  .el-input {
    width: 165px;
    margin-right: 5px;

    .el-input__inner {
      background: #000000;
      border: 1px solid #004fff;
      color: #dce9ff;
    }
  }

  .el-radio-group {
    .el-radio {
      .el-radio__input {
        &.is-checked {
          .el-radio__inner {
            border-color: #004fff;
            background: #004fff;
          }
        }
      }

      .el-radio__label {
        color: #cbd0eb;
      }
    }
  }

  .el-button {
    background: #004fff;
    color: #000;
    border: none;
  }
}

::v-deep .el-slider__bar {
  background: #fff;
}

.text {
  width: 203px;
  height: 24px;
  display: flex;
  background: rgba(13, 34, 79, 0.65);
  border: 1px solid #004fff;
  border-radius: 12px;
  text-indent: 1rem;
 color: #fff;
  .speed {
    width: 41px;
    // background: #FFFFFF;
    color: #ffffff;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
  }

  .pitch {
    font-size: 12px;
    margin-left: 12px;
    width: 34px;
    height: 24px;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
    color: #fff;
  }
  .green{
    color:#00eb02
  }
  .yellow{
    color:#b4b428
  }
  .or{
    color: #b17c44
  }
  .red{
    color:#d42008
  }
}

.iconfont {
  color: #004fff;
}

// 变焦功能
::v-deep .el-slider__runway {
  visibility: hidden;
}
</style>
